<template>
  <div>

    <BlockViewer header="Carteira de Clientes"  >
      <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div class="grid">


          <div class="col-12 md:col-6 lg:col-4">
            <AutoComplete placeholder="Filtrar clientes pela cidade"
                          :dropdown="true"
                          :multiple="false"
                          v-model="selectedAutoValueCidade"
                          :suggestions="autoFilteredCidade"
                          @complete="searchCidade($event)"
                          field="descricao"
                          style="width: 100%; "
                          @item-select="selecionaCidade($event)">
              <template #item="slotProps" style="width: 20%">
                <div class="p-fluid formgrid grid">
                  <div style="width: 60%">{{slotProps.item.descricao}}</div>
                  <div style="width: 10%"  class="text-right">{{slotProps.item.estado}}</div>
                </div>
              </template>
            </AutoComplete>
            <p>
              <download-excel v-if="clientes.length > 0"
                              class="mt-3"
                              :data="clientes"
                              :fields="json_export_fields"
                              name="planilha_clientes.xls">
                Download Excel
                <i class="ml-2 pi pi-download text-blue-500 text-xl"></i>
              </download-excel>
            </p>


          </div>


          <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Contagem Total de Clientes</span>
                  <div class="text-900 font-medium text-xl">{{ formatInteiro(totalClientes)}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-users text-blue-500 text-xl"></i>
                </div>
              </div>
              <span class="text-orange-500 font-medium">{{ formatInteiro(totalClientesElab)}} em elaboração</span>
              <!--                            <span class="text-500">since last visit</span>-->
            </div>
          </div>

          <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Com pedidos neste mês</span>
                  <div class="text-900 font-medium text-xl"> {{ formatInteiro(totalClientesComprouMes) }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-shopping-cart text-orange-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium"> {{ formatInteiro(totalClientesComprouAno) }} </span>
              <span class="text-500"> este Ano</span>
            </div>
          </div>


        </div>
      </div>
    </BlockViewer>



    <DataTable ref="dataTable" :value="clientes" v-model:selection="selectedClientes" dataKey="id" :paginator="true" :rows="10"
               v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clientes" responsiveLayout="scroll">
      <template #header>
        <div class="flex justify-content-between flex-column sm:flex-row">
          <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
          <!--
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search" />
            <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
          </span>
          -->
        </div>
      </template>

      <Column field="id" header="ID" :sortable="true" filterField="id" :showFilterMatchModes="false" >
        <template #body="slotProps">
          <span class="p-column-title">ID</span>
          {{slotProps.data.id}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo id"/>
        </template>
      </Column>

      <Column field="codexterno" header="Código" :sortable="true" filterField="codexterno" :showFilterMatchModes="false" >
        <template #body="slotProps">
          <span class="p-column-title">Id</span>
          {{slotProps.data.codexterno}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
        </template>
      </Column>

      <Column field="nome" header="Nome" :sortable="true" filterField="nome" >
        <template #body="slotProps">
          <span class="p-column-title">Nome</span>
          {{slotProps.data.nome}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo nome"/>
        </template>
      </Column>

      <Column field="cnpj" header="CNPJ" :sortable="true" filterField="cnpj" >
        <template #body="slotProps">
          <span class="p-column-title">CNPJ</span>
          {{slotProps.data.cnpj}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo CNPJ"/>
        </template>
      </Column>

      <Column field="fantasia" header="Fantasia" :sortable="true" filterField="fantasia" >
        <template #body="slotProps">
          <span class="p-column-title">Fantasia</span>
          {{slotProps.data.fantasia}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo nome fantasia"/>
        </template>
      </Column>

      <Column field="cidade" header="Cidade" :sortable="true" class="text-left" filterField="cidade">
        <template #body="slotProps">
          <span class="p-column-title">Cidade</span>
          {{formatCurrency(slotProps.data.cidade)}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
        </template>
      </Column>

      <Column field="estado" header="Estado" :sortable="true" class="text-right" filterField="estado">
        <template #body="slotProps">
          <span class="p-column-title">Estado</span>
          {{slotProps.data.estado}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
        </template>
      </Column>

      <Column field="categ" header="Categoria" :sortable="true" filterField="categ">
        <template #body="slotProps">
          <span class="p-column-title">Categoria</span>
          {{slotProps.data.categ}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela categoria"/>
        </template>
      </Column>

      <Column field="status" header="Status" :sortable="true" filterField="status">
        <template #body="slotProps">
          <span class="p-column-title">Status</span>
          <span :class="'cliente-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status"/>
        </template>
      </Column>

      <Column header="Última Compra" dataType="date" style="min-width:10rem">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.dtultped) }}
        </template>
      </Column>

      <Column header="Último Atendimento" dataType="date" style="min-width:10rem">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.dtultped) }}
        </template>
      </Column>

      <Column field="limite" header="Limite" :sortable="true" class="text-right" filterField="limite">
        <template #body="slotProps">
          <span class="p-column-title">Limite</span>
          {{formatCurrency(slotProps.data.limite)}}
        </template>
        <template #filter="{filterModel}">
          <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
        </template>
      </Column>

      <Column header="Ações" class="text-right">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2" @click="$router.push('clientedetalhe/' + slotProps.data.id)" />
          <!-- <Button icon="pi pi-eye" class="p-button-rounded p-button-info mr-2 mb-2" @click="$router.push('pedidopreview')" />-->
          <!-- <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2 mb-2" @click="visibleTimeline = true" /> -->
        </template>
      </Column>





    </DataTable>

  </div>

</template>

<script>
import BlockViewer from "@/BlockViewer";
import PedidoService from '@/service/PedidoService';
import {FilterMatchMode, FilterOperator} from "primevue/api";
import moment from 'moment'


export default {
  name: 'Free',
  components: {BlockViewer},
  data() {
    return {


      totalClientes: 0,
      totalClientesElab: 0,
      totalClientesComprouMes: 0,
      totalClientesComprouAno: 0,


      contaTotal: 0,
      contaTotalAtend: 0,
      selectedAutoValueCidade: null,
      autoFilteredCidade: [],
      autoValueCidade: [],
      json_export_fields: {
        "ID": "id",
        "CODIGO": "codexterno",
        "CNPJ": "cnpj",
        "NOME": "nome",
        "FANTASIA": "fantasia",
        "CIDADE": "cidade",
        "ESTADO": "estado",
        "STATUS": "status",
        "EMAIL": "email",
        "RUA": "rua",
        "NUMERO": "numero",
        "COMPL": "compl",
        "BAIRRO": "bairro",
        "CEP": "cep",
        "TELEFONE": "tel1",
        "TELEFONE2": "tel2",
        "TELEFONE3": "tel3",
        "CONTATO": "contato",
        "REPRESENTANTE": "repres",
        "ULTCOMPRA":{
          field: 'ultcompra',
          callback: (value) => {
            return `${this.formatDate(value)}`;
          }
        },
        "LIMITE":{
          field: 'limite',
          callback: (value) => {
            return `${this.formatDecimal(value)}`;
          }
        },
      },

      clientes: [],
      pessoaData: [],

      selectedAutoValueCliente: null,
      autoFilteredCliente: [],
      autoValueCliente: [],

      representantes: null,
      selectedClientes: null,
      loading1: false,
      filters1: null,
    }
  },
  pedidoService: null,
  created() {
    this.pedidoService = new PedidoService();
    this.initFilters();

  },
  mounted() {

    this.getClientes()

  },

  methods: {

    getClientes(){
      this.loading1 = true
      this.pedidoService.getCarteiraClientes(this.selectedAutoValueCidade).then((dataClientes) => {
        console.log('getCarteiraClientes')
        console.log(dataClientes)
        this.clientes = dataClientes[0]
        var totalCli = 0
        var totalCliElab = 0
        var totalCliComprouMes = 0
        var totalCliComprouAno = 0
        var dataAgora = moment.now()

        this.clientes.forEach((cliente) => {
          cliente.limite = parseFloat(cliente.limite)
          cliente.created_at = moment(cliente.created_at).toDate()
          //cliente.dtultped = moment.isDate(cliente.dtultped) ? moment(cliente.dtultped).toDate() : null
          cliente.dtultped = moment(cliente.dtultped).toDate()

          totalCli += 1
          if (cliente.status === 'EL'){
            totalCliElab += 1
          }

          if (moment(cliente.dtultped).isValid()){
            // console.log('cliente.dtultped', cliente.dtultped, moment(cliente.dtultped).isValid())
            let difMeses = moment(cliente.dtultped).isSame(dataAgora, 'month')
            let difAnos = moment(cliente.dtultped).isSame(dataAgora, 'year')
            // console.log('difMeses')
            // console.log(difMeses)
            if (difMeses) {
              totalCliComprouMes += 1
            }
            if (difAnos) {
              totalCliComprouAno += 1
            }
          }
        });

        this.totalClientes = totalCli
        this.totalClientesElab = totalCliElab
        this.totalClientesComprouMes = totalCliComprouMes
        this.totalClientesComprouAno = totalCliComprouAno

        this.loading1 = false
      });
    },

    selecionaCidade(event){
      console.log('selecionaCidade')
      console.log(event)
      this.getClientes()
      // console.log(event.value)
      // this.clienteData['cidade'] = event.value.descricao
      // this.clienteData['estado'] = event.value.estado
    },

    searchCidade(event){
      console.log('searchCidade')
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCidade = [...this.autoValueCidade];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqCidade().then((data) => {
            // console.log('getPesqCidade')
            // console.log(data)
            this.autoValueCidade = data
            this.autoFilteredCidade = this.autoValueCidade.filter((cidade) => {
              return cidade.descricao.toLowerCase().startsWith(event.query.toLowerCase());
            });
          });

        }
      }, 250);
    },

    exportExcel(){
      console.log('exportExcel')
      this.$refs.dataTable.exportCSV()
    },



    formatDate(value) {
      if (value){
        return value.toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
      return 'sem registro'

    },

    formatDateTime(value) {
      // console.log('value formatDate')
      // console.log(value)
      // console.log(moment())
      const format = "DD/MM/YYYY HH:mm:ss"
      var date = new Date(value);
      let dateTime = moment(date).format(format);
      // console.log(dateTime1)
      return dateTime
    },

    formatDecimal(value) {
      if (value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    formatInteiro(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 0});
      return 0;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'codexterno': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'nome': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cnpj': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'fantasia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'categoria': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'limite': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }

    },


    clearFilter1() {
      this.initFilters();
    },
  }
}
</script>
<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
